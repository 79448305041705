// @ts-nocheck
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../app/shared/Spinner';
import { loginActions } from 'store/login-slice';


const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const Asset = lazy(() => import('./pages/Asset'));
const Users = lazy(() => import('./pages/Users'));
const Documents = lazy(() => import('./pages/Documents'));
const Checklist = lazy(() => import('./pages/Checklist'));
const EditChecklist = lazy(() => import('./pages/EditChecklist'));
const Activity = lazy(() => import('./table-view/Acitivity'));
const Response = lazy(() => import('./pages/Response'));
const Move = lazy(() => import('./pages/Move'));

// const Widgets = lazy(() => import('./widgets/Widgets'));

// const KanbanBoard = lazy(() => import('./apps/KanbanBoard'));
// const Chats = lazy(() => import('./apps/Chats'));
// const TodoList = lazy( () => import('./apps/TodoList'));

// const Accordions = lazy(() => import('./basic-ui/Accordions')); 
// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Badges = lazy(() => import('./basic-ui/Badges'));
// const Breadcrumbs = lazy(() => import('./basic-ui/Breadcrumbs'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Modals = lazy(() => import('./basic-ui/Modals'));
// const Progress = lazy(() => import('./basic-ui/Progress'));
// const Paginations = lazy(() => import('./basic-ui/Paginations'));
// const TabsPage = lazy(() => import('./basic-ui/TabsPage'));
// const Typography = lazy(() => import('./basic-ui/Typography'));
// const Popups = lazy(() => import('./basic-ui/Popups'));

// const Dragula = lazy(() => import('./advanced-ui/Dragula'));
// const Clipboard = lazy(() => import('./advanced-ui/Clipboards'));
// const ContextMenu = lazy(() => import('./advanced-ui/ContextMenus'));
// const Sliders = lazy(() => import('./advanced-ui/Sliders'));
// const Carousel = lazy(() => import('./advanced-ui/Carousel'));
// const Loaders = lazy(() => import('./advanced-ui/Loaders'));
// const TreeView = lazy(() => import('./advanced-ui/TreeView'));

// const BasicElements = lazy(() => import('./form-elements/BasicElements'));
// const AdvancedElements = lazy(() => import('./form-elements/AdvancedElements'));
// const Wizard = lazy(() => import('./form-elements/Wizard'));

// const BasicTable = lazy(() => import('./tables/BasicTable'));
// const ReactTable = lazy(() => import('./tables/ReactTable'));
// const SortableTable = lazy(() => import('./tables/SortableTable'));

// const VectorMap = lazy(() => import('./maps/VectorMap'));
// const SimpleMap = lazy(() => import('./maps/SimpleMap'));

// const Notifications = lazy(() => import('./notifications/Notifications'));

// const Mdi = lazy(() => import('./icons/Mdi'));
// const FlagIcons = lazy(() => import('./icons/FlagIcons'));
// const FontAwesome = lazy(() => import('./icons/FontAwesome'));
// const SimpleLine = lazy(() => import('./icons/SimpleLine'));
// const Themify = lazy(() => import('./icons/Themify'));
// const TypIcons = lazy(() => import('./icons/TypIcons'));

// const TextEditors = lazy(() => import('./editors/TextEditors'));
// const CodeEditor = lazy(() => import('./editors/CodeEditor'));

// const ChartJs = lazy(() => import('./charts/ChartJs'));
// const C3Charts = lazy(() => import('./charts/C3Charts'));
// const Chartist = lazy(() => import('./charts/Chartist'));
// const GoogleCharts = lazy(() => import('./charts/GoogleCharts'));
// const SparkLineCharts = lazy(() => import('./charts/SparkLineCharts'));
// const GuageChart = lazy(() => import('./charts/GuageChart'));

const Error404 = lazy(() => import('./error-pages/Error404'));
const Error500 = lazy(() => import('./error-pages/Error500'));

const Login = lazy(() => import('./user-pages/Login'));
const Logout = lazy(() => import('./user-pages/Logout'));
// const Login2 = lazy(() => import('./user-pages/Login2'));
// const Register1 = lazy(() => import('./user-pages/Register'));
// const Register2 = lazy(() => import('./user-pages/Register2'));
// const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

// const BlankPage = lazy(() => import('./general-pages/BlankPage'));
// const Profile = lazy(() => import('./general-pages/Profile'));
// const Faq = lazy(() => import('./general-pages/Faq'));
// const Faq2 = lazy(() => import('./general-pages/Faq2'));
// const NewsGrid = lazy(() => import('./general-pages/NewsGrid'));
// const Timeline = lazy(() => import('./general-pages/Timeline'));
// const SearchResults = lazy(() => import('./general-pages/SearchResults'));
// const Portfolio = lazy(() => import('./general-pages/Portfolio'));

// const Invoice = lazy(() => import('./ecommerce/Invoice'));
// const Pricing = lazy(() => import('./ecommerce/Pricing'));
// const ProductCatalogue = lazy(() => import('./ecommerce/ProductCatalogue'));
// const ProjectList = lazy(() => import('./ecommerce/ProjectList'));

// const Email = lazy(() => import('./apps/Email'));
// const Calendar = lazy(() => import('./apps/Calendar'));
// const Gallery = lazy(() => import('./apps/Gallery'));



const AppRoutes = () => {

  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Async function to validate token (if you fetch from server or have any async ops)
    const validateToken = async () => {
      // Simulating an API call
      return new Promise((resolve) => {
        setTimeout(() => {
          const hasValidToken = Boolean(localStorage.getItem('access_token'));
          resolve(hasValidToken);
        }, 1000); // Fake delay
      });
    };

    const checkLoginStatus = async () => {
      const hasValidToken = await validateToken();
      if (hasValidToken) {
        dispatch(loginActions.setLogin());
      } else {
        dispatch(loginActions.setLogout());
      }
      setIsLoading(false);
    };

    checkLoginStatus();
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>

        <Route exact path="/">
          {isLogin ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login">
          {isLogin ? <Redirect to="/dashboard" /> : <Login />}
        </Route>


        <Route path="/dashboard">
          {isLogin ? <Dashboard /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/logout" >
          <Logout />
        </Route>

        <Route exact path="/asset">
          {isLogin ? <Asset /> : <Redirect to="/login" />}


        </Route>

        <Route exact path="/documents">
          {isLogin ? <Documents /> : <Redirect to="/login" />}


        </Route>

        <Route exact path="/checklist">
          {isLogin ? <Checklist /> : <Redirect to="/login" />}


        </Route>

        <Route exact path="/move">
          {isLogin ? <Move /> : <Redirect to="/login" />}


        </Route>

        <Route exact path="/activity">
          {isLogin ? <Activity /> : <Redirect to="/login" />}


        </Route>

        <Route exact path="/edit_checklist/:id" render={(props) => (
          <>
            {isLogin ? <EditChecklist id={props.match.params.id} /> : <Redirect to="/login" />}
          </>
        )

        } />

        <Route exact path="/response/:id" render={(props) => (
          <>
            {isLogin ? <Response id={props.match.params.id} /> : <Redirect to="/login" />}
          </>
        )

        } />





        <Route exact path="/users">
          {isLogin ? <Users /> : <Redirect to="/login" />}


        </Route>

        <Route path="/error-404" component={Error404} />
        <Route path="/error-500" component={Error500} />

      

        <Redirect to="/dashboard" />

      </Switch>
    </Suspense>
  );
}

export default AppRoutes;