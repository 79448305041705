import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
 
    return (
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
              <span className="icon-menu"></span>
            </button>
            <Link className="navbar-brand brand-logo" to="/"><img 
// @ts-ignore
            src={require("../../assets/images/logo.png") } className="me-2" alt="logo" /></Link>
            <Link className="navbar-brand brand-logo-mini" to="/"><img 
// @ts-ignore
            src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
          <ul className="navbar-nav">
            <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
              
              <h3 className="welcome-sub-text">AM Dashboard </h3>
            </li>
          </ul>
          <ul className="navbar-nav navbar-nav-right">
          
            
            <li className="nav-item">
              <form className="search-form" action="#">
                <i className="icon-search"></i>
                <input type="search" className="form-control" placeholder="Search Here" title="Search here" />
              </form>
            </li>
          
         
            <li className="nav-item nav-profile">
              <Dropdown>
                <Dropdown.Toggle className="nav-link count-indicator">
                  <img 
// @ts-ignore
                  src={require("../../assets/images/faces/face28.jpg")} alt="profile"/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="preview-list navbar-dropdown">
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex align-items-center">
                      <i className="ti-settings text-primary"></i>
                      <span className="ps-2">Settings</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item className="dropdown-item preview-item" onClick={evt =>evt.preventDefault()}>
                    <div className="d-flex align-items-center">
                      <i className="ti-power-off text-primary"></i>
                      <span className="ps-2">Logout</span>
                    </div>  
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="icon-menu"></span>
          </button>
        </div>
      </nav>
    );
  
}

export default Navbar;
